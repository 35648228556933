.card-box {
    position: relative;
    color: #fff;
    padding: 10px 10px 20px;
    margin: 5px 0px;
    border-radius: 10px;
    left: -5px;
}
/* .card-box:hover .icon-box{
    text-decoration: none;
    color: #f1f1f1;
}
.card-box .icon-box {
    position: absolute;
    top: auto;
    bottom: 5px;
    right: 5px;
    z-index: 0;
    font-size: 72px;
} */
/* .card-box:hover .icon-box {
    transition: 1s;
    -webkit-transition: 1s;
} */
.card-box .inner {
    padding: 3px 5px 0 5px;
}
.card-box h3 {
    font-size: 27px;
    font-weight: bold;
    margin: 0 0 8px 0;
    white-space: nowrap;
    padding: 0;
    text-align: left;
}
.card-box p {
    font-size: 15px;
}
.card-box .img {
    position: absolute;
    top: auto;
    bottom: 5px;
    right: 5px;
    z-index: 0;
    font-size: 72px;
    color: rgba(0, 0, 0, 0.15);
}
.card-box .card-box-footer {
    position: absolute;
    left: 0px;
    bottom: 0px;
    text-align: center;
    padding: 3px 0;
    color: rgba(255, 255, 255, 0.8);
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    text-decoration: none;
}
.card-box:hover .card-box-footer {
    background: rgba(0, 0, 0, 0.3);
}
.bg-blue {
    background-color: #7F1122!important;
}
.bg-green {
    background-color: #7F1122 !important;
}
.bg-orange {
    background-color: #7F1122 !important;
}
.bg-red {
    background-color: #7F1122 !important;
}
