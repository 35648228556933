@media (max-width: 1994px) {
  .nav {
    /* position: fixed; */
    top: 0;
    width: 100%;
    z-index: 9999;
    background: transparent;
  }
  .nav img {
    width: 50px;
  }
  .nav-bg {
    background: #000000;
  }
  .jumbotron {
    background-image: url(/public/assets/img/bg.jpg);
    background-size: cover;
    height: 450px;
  }
  .btn-regis{
    outline: #fff;
  }
  .btn-regis:hover{
    color: #201e37;
  }
  .scroll-down {
    height: 50px;
    width: 30px;
    border: 2px solid white;
    position: absolute;
    left: 50%;
    bottom: 195px;
    border-radius: 50px;
    cursor: pointer;
  }
  .scroll-down::before,
  .scroll-down::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 50%;
    border: 2px solid white;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border-top: transparent;
    border-left: transparent;
    animation: scroll-down 1s ease-in-out infinite;
  }
  .scroll-down::after {
    top: 30%;
    animation-delay: 0.3s;
  }

  @keyframes scroll-down {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      top: 70%;
    }
  }

  /* about */
  .img-area img {
    width: 90%;
    padding: 20px;
    border-radius: 30px;
  }
  .about-text h2 {
    font-size: 45px;
    font-weight: 700;
    color: #201e37;
  }
  .about-text .about-bfb {
    line-height: 2;
  }
  .about-text .btn {
    background: #d93221;
    color: white;
    border-radius: 30px;
  }
  .text-container {
    margin-top: 67px;
  }

  /* court */
  #court {
    /* background-color: #d93221; */
    padding: 10px 0;
  }
  .card-court {
    /* transition: 0.3s ease; */
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  }
  .card-body {
    padding: 20px;
    margin-top: 15px;
  }
  .rental-prince {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
  }
  .btn-booking:hover {
    background-color: #000000;
    color: white;
  }
  /* .card-court:hover {
    transform: scale(1.1);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.6);
  } */

  /* footer */
  .footer.lpages {
    background-color: #201e37;
    padding: 0 20px;
    margin: 0 auto;
  }
  .copyright {
    margin: auto;
  }
}

@media (max-width: 741px) {
  .scroll-down {
    bottom: 30%;
  }
}

@media (max-width: 721px) {
  .scroll-down {
    bottom: 25%;
  }
}

@media (max-width: 448px) {
  .scroll-down {
    bottom: 20%;
  }
  #navbarNav.show .navbar-nav {
    margin-left: 0px !important;
  }
}

@media (max-width: 429px) {
  .scroll-down {
    bottom: 25%;
  }
}

@media (max-width: 329px) {
  .scroll-down {
    bottom: 23%;
  }
}

@media (max-width: 297px) {
  .scroll-down {
    bottom: 20%;
  }
}
