.box-border {
    border:1px solid #ddd; 
    border-radius:5px;
}

.box-border .column {
    padding-left:5px;
    padding-right:5px;
}
.card-barcode {
    max-width: 50em;
    flex-direction: row;
}
.card-barcode img {
    max-width: 250px;
    margin: auto;
    padding: 15px;
}
.codeqr {
    display: flex;
    justify-content: space-between;
}
.text-section {
    max-width: 70%;
}