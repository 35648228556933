* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
}
.container-loading{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ring {
    width: 200px;
    height: 200px;
    border: 0px solid #011015;
    border-radius: 50%;
    position: absolute;
}

.ring:nth-child(1){
    border-bottom-width: 8px;
    border-color: rgb(255, 0, 255);
    animation: rotate1 2s linear infinite;
}
.ring:nth-child(2){
    border-right-width: 8px;
    border-color: rgb(0, 247, 255);
    animation: rotate2 2s linear infinite;
}
.ring:nth-child(3){
    border-top-width: 8px;
    border-color: rgb(0, 255, 13);
    animation: rotate3 2s linear infinite;
}
.loading-text {
    color: rgb(9, 9, 9);
    letter-spacing: 2px;
    font-size: 16px;
}
@keyframes rotate1{
    0%{
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100%{
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}
@keyframes rotate2{
    0%{
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100%{
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}
@keyframes rotate3{
    0%{
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100%{
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}